import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Seo from '../sharedComponents/seo/seo';
import NavBar from '../sharedComponents/nav/NavBar';
import Footer from '../sharedComponents/footer/Footer';

import styles from './index.module.scss';

const Layout = ({
  children,
  metaTitle,
  metaDescription,
}) => (
  <>
    <Helmet>
      <html lang="en-GB" />
    </Helmet>
    <Seo
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    />
    <NavBar />
    <div className={styles.MainSection}>
      {children}
    </div>
    <Footer />
  </>
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.shape.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
};
