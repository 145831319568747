import React from 'react';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';

import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.Container}>
    <div className={styles.SocialIcons}>
      <ul>
        <li>
          <a href="https://twitter.com/dlpdigital" title="DLPDigital on Twitter" target="_blank" rel="noreferrer">
            <FaTwitter />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/dlpdigital/" title="DLPDigital on LinkedIn" target="_blank" rel="noreferrer">
            <FaLinkedinIn />
          </a>
        </li>
      </ul>
    </div>
    <div className={styles.ContactDetails}>
      <p>
        DLPDigital, 77 Marsh Wall, London E14 9SH
      </p>
      <p>hello@dlpdigital.co.uk</p>
    </div>
  </div>
);

export default Footer;
