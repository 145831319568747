import React, { Component } from 'react';
import classNames from 'classnames';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { MenuItems } from './MenuItems';

import styles from './NavBar.module.scss';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  handleClick = () => {
    const { clicked } = this.state;
    this.setState({ clicked: !clicked });
  }

  render() {
    const { clicked } = this.state;
    return (
      <nav>
        <div className={styles.NavContainer}>
          <div className={styles.Logo}>
            <AnchorLink
              to="/"
              title="DLPDigital"
            >
              <img
                className={styles.NavbarLogo}
                src="/DLPDigital-300px-light.png"
                alt="DLPDigital"
                title="DLPDigital-300px-light.png"
              />
            </AnchorLink>
          </div>
          <div
            className={
              clicked ? classNames(styles.NavItems, styles.Active) : styles.NavItems
            }
          >
            <ul>
              {
                MenuItems.map((item) => (
                  <li>
                    <AnchorLink
                      to={item.url}
                      title={item.title}
                      className={item.cName}
                    >
                      {item.title}
                    </AnchorLink>
                  </li>
                ))
              }
            </ul>
          </div>
          <div
            className={styles.NavToggle}
            onClick={this.handleClick}
            onKeyDown={this.handleClick}
            role="button"
            tabIndex={0}
          >
            <i
              className={clicked ? styles.Cross : styles.Menu}
            />
          </div>
          <div
            className={
              clicked ? classNames(styles.LinkedIn, styles.Active) : styles.LinkedIn
            }
          >
            <a
              href="https://www.linkedin.com/company/dlpdigital/"
              title="View DLPDigital on LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <i />
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
