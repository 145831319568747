import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Seo = ({
  metaTitle,
  metaDescription,
}) => (
  <Helmet
    title={metaTitle}
    meta={[
      { name: 'description', content: metaDescription },
    ]}
  />
);

Seo.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default Seo;
