export const MenuItems = [
  {
    title: 'Services',
    url: '/',
    cName: 'NavLinks',
  },
  {
    title: 'Work',
    url: '/',
    cName: 'NavLinks',
  },
  {
    title: 'About',
    url: '/',
    cName: 'NavLinks',
  },
  {
    title: 'Blog',
    url: '/blog',
    cName: 'NavLinks',
  },
  {
    title: 'Contact',
    url: '/#contact',
    cName: 'NavLinks',
  },
];

export default {
  MenuItems,
};
